<template>
  <div>
    <!-- <ui-component-list :component-list="content" /> -->
    <ui-component :component-data="translationText" />
    <ui-component :component-data="translationRichtext" />
    <ui-component :component-data="translationComponents" />
  </div>
</template>

<script>
import translationText from "@/@core/layouts/components/input-fields/translatable-text-field/defaultObject";
import translationRichtext from "@/@core/layouts/components/input-fields/translatable-richtext-field/defaultObject";
import translationComponents from "@/@core/layouts/components/input-fields/translatable-components-field/defaultObject";
export default {
  data() {
    return {
      isModalShow: false,
      src: null,
      code: null,
      content: [],
      translationText: translationText,
      translationRichtext: translationRichtext,
      translationComponents: translationComponents,
    };
  },
  methods: {
    openModal() {
      this.isModalShow = !this.isModalShow;
    },
    cancel() {
      this.isModalShow = false;
    },
    update() {},
    clear() {
      this.code = null;
      this.src = null;
    },
  },
};
</script>
