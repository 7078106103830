export default {
  type: 'translatable-components',
  meta: {
    default_lang: 'vi'
  },
  content: [],
  translations: [
    {
      language: 'vi',
      content: []
    }
  ]
}
